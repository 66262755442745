import React, { useState, useEffect } from "react";
import { ListElement } from "./box-lista-style.js";
import "./score-style.css";
import "../../global-styles.css";
import api from "../../services/api.js";
import axios from "axios";
import Modal from "../modal/modal.js";
import { TextoClicavel } from "../modalAdicionar/adicionar-style.js";
import Busca from "../busca/busca";
import { Link, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateIcon from "@material-ui/icons/Create";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import { TextAreaModal, TextDivModal } from "../TextField/styles.js";
import cancelar from "../../assets/cancelar.svg";
import confirm from "../../assets/confirm.svg";
import eye from "../../assets/eye.svg";
import { IconImage, OptionsArea, TextTable } from "./styles.js";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";

function BoxRequisicao({ getRecomendations, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adicionarCategoriaIsOpen, setAdicionarCategoriaIsOpen] =
    useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [adicionarSugestaoIsOpen, setAdicionarSugestaoIsOpen] = useState(false);
  const [rejeitarIsOpen, setRejeitarIsOpen] = useState(false);
  const [nome, setNome] = useState(props.nome);

  const [opcoesCategoria, setOpcoesCategoria] = useState([]);

  const [categoria, setCategoria] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);

  const [categoriasFinais, setCategoriasFinais] = useState([]);
  const [novaCategoria, setNovaCategoria] = useState(props.categoria);
  const [marca, setMarca] = useState(props.marca);
  const [volume, setVolume] = useState(props.volume);
  const [upc, setUpc] = useState(props.upc);
  const [imagemFinal, setImagemFinal] = useState(props.imagem1);
  const [ingredientes, setIngredientes] = useState([]);
  const [mensagemErro, setMensagemErro] = useState(" ");
  const [loading, setLoading] = useState(false);
  const [whichImage, setWhichImage] = useState();
  const [imageOptions, setImageOptions] = useState();
  const [nomeImagem, setNomeImagem] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);

  const url = "/api/sugestoes/" + props.id + "/";

  useEffect(() => {
    let newOptions = [{ nome: "Nova imagem" }];

    if (props.imagem1) {
      newOptions = newOptions.concat([{ nome: "Imagem 1" }]);
    }

    if (props.imagem2) {
      newOptions = newOptions.concat([{ nome: "Imagem 2" }]);
    }

    setImageOptions(newOptions);
  }, [adicionarSugestaoIsOpen]);

  const adicionarProduto = async (e) => {
    e.preventDefault();
    if (nome === "") {
      setMensagemErro("Insira um nome");
      return;
    }

    if (categoriasFinais.length === 0) {
      setMensagemErro("Insira uma categoria");
      return;
    }

    if (marca === "") {
      setMensagemErro("Insira uma marca");
      return;
    }

    setLoading(true);

    let ingredientesId = [];
    ingredientesId = ingredientes.map((ingrediente) => {
      return ingrediente.id;
    });

    let imageUrl =
      whichImage === "Imagem 1"
        ? props.imagem1
        : whichImage === "Imagem 2"
        ? props.imagem2
        : nomeImagem;
    console.log(nomeImagem, props.imagem1);
    const body = {
      nome: nome,
      categorias: categoriasFinais,
      marca: marca,
      volume: volume,
      upc: upc,
      image_url: `https://rotulos-s3.s3.sa-east-1.amazonaws.com/appcosmeticos/${imageUrl}`,
      ingredientes: ingredientesId,
    };
    console.log(",hello", body);
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Produto",
        operation: "+",
      };
      const response1 = await api.post("/api/editionhistory/", bodyHistory);

      console.log(response1);
      console.log("url,", url, body);
      await api.patch(url, body);
      await api.post(`${url}validar/`, body);

      setAdicionarSugestaoIsOpen(false);
      setMessageIsOpen(true);
    } catch (error) {
      setMensagemErro(error.response.data.error);
    }
    setLoading(false);
  };

  const onFileUpload = async () => {
    if (!imagemFinal.type) {
      return;
    }

    const typeNovo = imagemFinal.type.substring([6]);
    setLoadingUpload(true);
    await api
      .get("/api/file/upload/" + typeNovo + "/")
      .then(async (res) => {
        const urlImagem = res.data.media_url;
        const imagem1 = res.data.file_name;
        setNomeImagem(res.data.file_name);
        let foo = imagemFinal.slice(0, imagemFinal.size, imagemFinal.type);
        let bar = new File([foo], res.data.file_name, {
          type: imagemFinal.type,
        });
        const formData = new FormData();
        formData.append("file_name", res.data.file_name);
        formData.append("file", imagemFinal);
        console.log("res.data.file_name", res.data.file_name);
        console.log("imagem", imagemFinal);

        await api
          .post("/api/file/upload/" + typeNovo + "/", formData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.error(err));
      setLoadingUpload(false);
  };

  const getCategoria = async () => {
    api
      .get("/api/categorias/all/")
      .then((response) => (response = setOpcoesCategoria(response.data)));
  };

  useEffect(() => {
    if (adicionarSugestaoIsOpen === true) {
      getCategoria();
    }
  }, [adicionarSugestaoIsOpen]);

  useEffect(() => {
    let categoriasId = [];
    if (categoria && categoria.length !== 0) {
      categoriasId[0] = categoria.id;
      subcategorias.forEach((subcategoria) => {
        categoriasId.push(subcategoria.id);
      });
      setCategoriasFinais(categoriasId);
    }
  }, [categoria, subcategorias]);

  const adicionarCategoria = async () => {
    const body = {
      nome: novaCategoria,
    };
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Categorias",
        operation: "+",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      api.post("/api/categorias/", body).then(getCategoria());
    } catch (error) {
      toast.error("Erro ao adicionar categoria", toastProps);
    }
  };

  const eventhandlerIngrediente = (data) => {
    let ingredientesId = [];
    for (let item of data) {
      ingredientesId.push(item.id);
    }
    ingredientesId = data;
    setIngredientes(ingredientesId);
  };

  const rejeitar = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Sugestao",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.put("/api/sugestoes/" + props.id + "/recusar/");
      toast.success("Sugestão rejeitada com sucesso", toastProps);
    } catch (error) {
      toast.error("Erro ao rejeitar sugestão", toastProps);
    }
    setLoading(false);
    setRejeitarIsOpen(false);
    setModalIsOpen(false);
    getRecomendations();
  };

  return (
    <ListElement>
      <ContainerHeaderTable
        width="32"
        justify="start"
        marginLeft={30}
        marginRight={10}
      >
        <TextTable>{props.nome}</TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        width="29"
        justify="center"
        marginLeft={0}
        marginRight={10}
      >
        <TextTable
          id="meio"

          // onClick={() => setModalIsOpen(true)}
        >
          {props.marca}
        </TextTable>
      </ContainerHeaderTable>

      <ContainerHeaderTable
        // width="15"
        minWidth="130"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          onClick={() => {
            setModalIsOpen(true);
          }}
          src={eye}
          width={23}
          height={23}
        />
        <IconImage
          onClick={() => {
            setAdicionarSugestaoIsOpen(true);
          }}
          src={confirm}
          width={20}
          height={20}
        />
        <IconImage
          onClick={() => {
            setRejeitarIsOpen(true);
          }}
          src={cancelar}
          width={20}
          height={20}
        />
      </ContainerHeaderTable>

      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalIsOpen(false)}
        >
          <h2 style={{ fontWeight: "bold" }}>{props.nome}</h2>
          <div className="inline" style={{ marginLeft: "7px" }}>
            {props.imagem1 && (
              <img
                src={props.imagem1}
                alt={props.nome}
                style={{ width: "300px", height: "300px" }}
              />
            )}
            {props.imagem2 && (
              <img
                src={props.imagem2}
                alt={props.nome}
                style={{ width: "300px", height: "300px" }}
              />
            )}
            {props.ingredientes && (
              <img
                src={props.ingredientes}
                alt={props.nome}
                style={{ width: "300px", height: "300px" }}
              />
            )}
          </div>
          {props.marca && (
            <>
              <p style={{ marginBottom: "1px", fontWeight: "bold" }}>Marca:</p>
              <p style={{ marginBottom: "2px" }}>{props.marca}</p>
            </>
          )}
          {props.descricao && (
            <>
              <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                descricao:
              </p>
              <p style={{ marginBottom: "2px" }}>{props.descricao}</p>
            </>
          )}
          {props.upc && (
            <>
              <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                Código de barras:
              </p>
              <p style={{ marginBottom: "2px" }}>{props.upc}</p>
            </>
          )}
          {props.name && (
            <>
              <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                Nome do Usuário:
              </p>
              <p style={{ marginBottom: "2px" }}>{props.name}</p>
            </>
          )}
          {props.email && (
            <>
              <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                Email do Usuário:
              </p>
              <p style={{ marginBottom: "2px" }}>{props.email}</p>
            </>
          )}
        </Modal>
      ) : null}
      {adicionarSugestaoIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setAdicionarSugestaoIsOpen(false)}
        >
          <form>
            <label htmlFor="nome">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="categorias">
              Categoria<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesCategoria}
                loadingText={"Carregando Categorias..."}
                autoHighlight
                getOptionLabel={(option) => option?.nome}
                onChange={(selected, values) => setCategoria(values)}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <label htmlFor="categorias">Subcategorias</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesCategoria}
                loadingText={"Carregando Subcategorias..."}
                onChange={(selected, values) => setSubcategorias(values)}
                autoHighlight
                multiple
                getOptionLabel={(option) => option?.nome}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAdicionarCategoriaIsOpen(true)}
              >
                Nova Categoria
              </button>
            </TextoClicavel>
            <label htmlFor="ingredientes">Ingredientes</label>
            <TextDivModal>
              <Busca
                url="/api/ingredientes-sw/"
                listOld={ingredientes}
                addAction
                onChange={eventhandlerIngrediente}
              />
            </TextDivModal>
            <label htmlFor="marca">
              Marca<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="volume">Volume</label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="volume"
                value={volume || ""}
                onChange={(event) => setVolume(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="upc">Código de Barras</label>
            <TextDivModal height="40">
              <TextField
                style={{ width: 300 }}
                type="text"
                id="upc"
                value={upc || ""}
                onChange={(event) => setUpc(event.target.value)}
              />
            </TextDivModal>
            <label id="imagem">Imagem</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={imageOptions}
                autoHighlight
                getOptionLabel={(option) => option?.nome}
                onChange={(value, selected) => setWhichImage(selected?.nome)}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            {whichImage === "Nova imagem" ? (
              <>
                <input
                  style={{ width: 300, marginTop: 15, height: "max-content" }}
                  id="imagem"
                  type="file"
                  onChange={(event) => setImagemFinal(event.target.files[0])}
                  accept="image/*"
                />
                {loadingUpload && (
                  <ReactLoading
                    type="spokes"
                    color="#E6AC86"
                    height={20}
                    width={20}
                  />
                )}
                <TextoClicavel>
                  <button
                    type="button"
                    onClick={() => onFileUpload()}
                    disabled={!imagemFinal}
                  >
                    Confimar Imagem
                  </button>
                </TextoClicavel>
              </>
            ) : null}
            <button
              style={{ width: 300, marginBottom: "10px", cursor: "pointer" }}
              type="submit"
              onClick={(e) => adicionarProduto(e)}
              className="enviar"
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Alterar"
              )}
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}
      {adicionarCategoriaIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => setAdicionarCategoriaIsOpen(false)}
        >
          <p>Adicione uma nova categoria</p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label id="nova">Nova Categoria</label>
            <input
              id="nova"
              type="text"
              value={novaCategoria}
              onChange={(event) => setNovaCategoria(event.target.value)}
            />
            <input
              type="button"
              value="Adicionar"
              onClick={(e) => adicionarCategoria(e)}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}
      {messageIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => setMessageIsOpen(false)}
        >
          <p>Produto Adicionado com Sucesso!</p>
        </Modal>
      ) : null}
      {rejeitarIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => setRejeitarIsOpen(false)}
        >
          <p>Deseja mesmo rejeitar essa sugestão?</p>
          <p>{props.nome}</p>
          <button
            type="button"
            onClick={(event) => rejeitar(event)}
            className="botaoSubmit"
            style={{
              width: "180px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <ReactLoading type="spin" color="#fff" height={20} width={20} />
            ) : (
              "Confirmar"
            )}
          </button>
        </Modal>
      ) : null}
    </ListElement>
  );
}
export default BoxRequisicao;
